/* add css module styles here (optional) */

._3Ifyx {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5em;
  font-size: 2em;
}

._FcUUP {
  font-size: 15px;
  border: none;
}

._3sXmF {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
}

._1qEzs {
  margin-left: 2%;
}

._2z69Y {
  width: -moz-fit-content;
  width: fit-content;
  height: 1rem;
}